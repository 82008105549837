import React, { Component } from 'react'

class App extends Component {
    render() {
        const path = window.location.pathname;
        const iframeSrc = `https://onecompiler.com/embed${path}?hideTitle=true&listenToEvents=true`;
        return (
             <iframe id="onecompiler" frameBorder="0" height="100%" width="100%" 
             src={iframeSrc}>
                <p>Your browser does not support iframes.</p>
            </iframe>
        )
    }
}

export default App
